<template>
  <div 
    class="info-app-box"
    :class="{'no-active': isEnabled}"
  >
    <div class="info-app-box-body">
      <div class="info-app-box-body-content">
        <div class="logo-container">
          <img :src="logo" alt="wherex risk" class="platform-logo"/>
        </div>
        <p class="mb-6">
          <span v-html="$t('WherexRiskLabel')"></span>
        </p>
        <button 
          id="risk-button" 
          class="btn btn-primary" 
          type="button"
          @click="handleButtonRisk"
        >
          {{ isEnabled ?  $t("SubmitLogin"):$t("LabelRegisterRisk1") }}
        </button>
        <div 
          class="info-app-box-alert"
          :class="[isEnabled ? 'info-app-box-alert-success':'info-app-box-alert-danger',
          'alert-adjustment-risk'
          ]"
        >
          <i :class="isEnabled ? 'fa-solid fa-circle-check' : 'fa-solid fa-circle-exclamation'"></i>
          <div>
            <span v-html="isEnabled ? $t('OkCompany'):$t('RiskTerms') "></span>
            <a 
              v-if="!isEnabled"
              id="risk-term-link" 
              href="#"
              @click="goRiskTerms"
            >
              {{ $t('RiskTermLink') }}
            </a>
          </div>
        </div>
        <div class="pay-term-container">
          <span v-html="isEnabled ? $t('RiskTerms'):''"></span>
          <a
          v-if="isEnabled"
          id="risk-term-link"
          href="#"
          @click="goRiskTerms"
          >
          {{ $t('RiskTermLink') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// import amplitude from "amplitude-js";
  import logo from "../assets/images/wherex-logo-risk.svg";
  export default {
    components: {},
    data: function (){
      return{
        logo: logo,
      };
    },
    props:{
      isEnabled: Boolean,
      isUserSystem:Boolean,
    },
    mounted() {},
    methods: {
      goRisk: function() {
        let redirectUrl = this.$utils.getRedirectUrl(
          this.$riskLogin,
          this.$local,
          this.$whiteList
        );
        let tokenForm = this.$utils.getTokenForm(redirectUrl, this.$clientId, this.$userInfo.getEmail(this.$clientId))
        document.getElementById("tokenForm").appendChild(tokenForm);
        tokenForm.submit();
      },
      goRiskTerms: function () {
        window.open(this.$riskTerms);
      },
      goRiskNew: function(){
        window.open(this.$riskUrlInfo);
      },
      handleButtonRisk(){
        if(this.isEnabled){
          this.goRisk();
        }else{
          this.goRiskNew();
        }
      }
    },
  };
  </script>