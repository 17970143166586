<template >
  <div class="top-bar" >
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex align-items-center">
          <div>
            <img  class="top-bar-logo align-middle" :src="logo" alt="Wherex Logo"  />
          </div>
          <div class="ms-auto">
            <a v-on:click="goInstantLogOutUrl" style="cursor: pointer">{{
              $t("Exit")
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div class="container mt-5" style="padding-top: 40">     
        <div class="row">
            <div class="col">
                <h1 class="hello-title">{{$t("Hello")}}</h1>
                <p class="landing-label">{{$t("LandingLabel")}}</p>
            </div>
        </div>
    </div>
    <LandingCard
      v-bind:sourcing="this.sourcing"
      v-bind:pay="this.pay"
      v-bind:risk="this.risk"
      v-if="this.renderComponent"
    />
</template>

<script>
// @ is an alias to /src
import logo from "../assets/images/wherex-logo.svg";
import LandingCard from "@/components/LandingCard.vue";
import { CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";
export default {
  name: "Landing",
  components: {
    LandingCard,
  },
  methods: {
    goInstantLogOutUrl: function () {
      const service = this.$route.query.srv;
      const locale = this.$i18n.locale;
      window.location.href = this.$utils.getInstantLogOutUrl(locale, service);
    },
  },
  data() {
    return {
      sourcing: false,
      pay: false,
      risk: false,
      renderComponent: false,
      logo:logo
    };
  },
  async mounted() {
    var locale = this.$route.query.lang;
    if (locale != undefined && locale != "undefined") {
      this.$i18n.locale = locale;
    } else {
      this.$i18n.locale = navigator.language.split("-")[0];
    }
    this.isLogin = !this.$userInfo.isLogin(this.$clientId);
    if (this.$userInfo.isLogin(this.$clientId)) {
      const url = this.$route.query.srv;
      const locale = this.$route.query.lang;
      const email = this.$userInfo.getEmail(this.$clientId);
      var poolData = this.$cog.poolData(this.$userPoolId, this.$clientId);
      var user = new CognitoUser({
        Username: this.$userInfo.getEmail(this.$clientId),
        Pool: poolData,
      });
      var token = new CognitoRefreshToken({
        RefreshToken: this.$userInfo.getRefresh(this.$clientId, email),
      });
      await user.refreshSession(token, async (err) => {
        if (err != null) {
          localStorage.removeItem("email");
          document.cookie = "wherexCookie" + "=" + "" + ";" + "5m" + ";path=/";
          user.signOut();
          window.location.href = this.$utils.getHomeUrl(locale, url);
        } else {
          document.cookie = "wherexCookie" + "=" + this.$userInfo.getAcces(this.$clientId,this.$userInfo.getEmail(this.$clientId)) + ";" + "5m" + ";path=/";
          await user.getUserAttributes((err, data) => {
            if (err != null) {
              localStorage.removeItem("email");
              document.cookie = "wherexCookie" + "=" + "" + ";" + "5m" + ";path=/;domain=.wherex.com;";
              user.signOut();
              window.location.href = this.$utils.getHomeUrl(locale, url);
            } else {
              for (var i = 0; i < data.length; i++) {
                if (data[i].Name == "custom:fintech" && data[i].Value == "1") {
                  this.pay = true;
                }
                if (data[i].Name == "custom:system" && data[i].Value == "1") {
                  this.sourcing = true;
                }
                if (data[i].Name == "custom:risk" && data[i].Value == "1") {
                  this.risk = true;
                }
              }
            }
            this.renderComponent = true;
          });
        }
      });
    } else {
      const url = this.$route.query.srv;
      const locale = this.$route.query.lang;
      window.location.href = this.$utils.getHomeUrl(locale, url);
    }
  },
};
</script>
<style>
@import "../assets/css/landing.css";
@import "../assets/css/bootstrap.min.css";

</style>
