<template>
  <div
  class="info-app-box"
  :class="{'no-active': isEnabled}" 
  >
  <div class="info-app-box-body">
    <div class="info-app-box-body-content">
      <div class="logo-container">
        <img :src="logo" alt="wherex pay" class="platform-logo"/>
      </div>
      <p class="mb-6">
        <span v-html="$t('WherexPayLabel')"></span>
      </p>
      <button
        id="pay-button"
        class="btn btn-primary"
        type="button"
        :style="{marginTop: this.$i18n.locale === 'en' ? '45px' : '70px'}"
        @click="handleButtonPay" 
        >
        {{ isEnabled ? $t("SubmitLogin"):$t("ActivatePay") }}
      </button>
      <div
      class="info-app-box-alert"
      :class="[isEnabled ? 'info-app-box-alert-success':'info-app-box-alert-danger',
        'alert-adjustment-pay'
      ]"
      >
        <i :class="isEnabled ? 'fa-solid fa-circle-check':'fa-solid fa-circle-exclamation'"></i>
        <div>
          <span v-html="isEnabled ? $t('OkCompany'):$t('PayTerm')"></span>
          <a
            v-if="!isEnabled"
            id="pay-term-link"
            href="#"
          >
          {{ $t('PayTermLink') }}
          </a>
        </div>
      </div>
      <div class="pay-term-container">
    <span v-html="isEnabled ? $t('PayTerm') : ''"></span>
    <a
      v-if="isEnabled"
      id="pay-term-link"
      href="#"
    >
      {{ $t('PayTermLink') }}
    </a>
  </div>
    </div>
  
  </div>
  
  
  </div>
</template>
  
  <script>
  import amplitude from "amplitude-js";
  import logo from "../assets/images/wherex-pay-logo.svg";
  export default {
    components: {},
    data: function () {
      return {
        logo: logo,
      };
    },
    props: {
      isEnabled: Boolean,
      isUserSystem:Boolean,
    },
    mounted() {},
    methods: {
      goPay: function () {
        let redirectUrl = this.$utils.getRedirectUrl(
          this.$payLogin,
          this.$local,
          this.$whiteList
        );
        let tokenForm = this.$utils.getTokenForm(redirectUrl, this.$clientId,this.$userInfo.getEmail(this.$clientId))
        document.getElementById("tokenForm").appendChild(tokenForm);
        tokenForm.submit();
      },
      goPayTerms: function () {
        window.open(this.$payTerms);
      },
      goInfoPay: function () {
        const email = this.$userInfo.getEmail(this.$clientId);
  
        if (this.$env == "PROD") {
          let eventProperties = { email: email };
          amplitude.getInstance().init("5ed9ed7f39125be524c67fff25d6263b");
          amplitude
            .getInstance()
            .logEvent("AmpLoginPayActivate", eventProperties);
        } else {
          let eventProperties = { email: email };
          amplitude.getInstance().init("fad566719c2099f78ef6175acb3c70a9");
          amplitude
            .getInstance()
            .logEvent("AmpLoginPayActivate", eventProperties);
        }
        if (this.isUserSystem == true){
          let redirectUrl = this.$utils.getRedirectUrl(
            this.$payOneClick,
            this.$local,
            this.$whiteList
          );
  
          let tokenForm = this.$utils.getTokenForm(redirectUrl, this.$clientId,this.$userInfo.getEmail(this.$clientId))
          document.getElementById("tokenForm").appendChild(tokenForm);
          tokenForm.submit();
        }else{
          window.open(this.$payInfo1);
        }
      },
      handleButtonPay(){
        if(this.isEnabled){
          this.goPay();
        } else{
          this.goInfoPay();
        }
      }
    },
  };
  </script>
  
  <style>
  @import "../assets/css/landing.css";
  </style>