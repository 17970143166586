<template>
  <div :class="['info-app-box', {'no-active': isEnabled}]">
    <div class="info-app-box-body">
      <div class="info-app-box-body-content">
        <div class="logo-container">
          <img id="system-logo" class="platform-logo" :src="logo" alt="wherex sourcing"/>
        </div>
        <p class="mb-6">
          <span v-html="$t('WherexSourcingLabel')"></span>
        </p>
        <button
        v-if="isEnabled" 
          class="btn btn-primary"
          id="system-button"
          type="button"
          @click="goSourcing"
        >
          {{ $t("SubmitLogin") }}
        </button>
        <div v-else class="d-flex justify-content-between flex-column flex-md-row buttons-container">
          <div class="item">
            <h4 id="sourcing-label2">{{ $t("LabelSourcing2") }}</h4>
            <a
              id="sourcing-button2"
              class="btn btn-primary"
              :href="$sourcingInfo2"
              target="_blank"
            >
              {{ $t("LabelRegisterSourcing2") }}
            </a>
          </div>
          <div class="item">
            <h4 id="sourcing-label1">{{ $t("LabelSourcing1") }}</h4>
            <a
              id="sourcing-button1"
              class="btn btn-primary"
              :href="$sourcingInfo1"
              target="_blank"
            >
              {{ $t("LabelRegisterSourcing1") }}
            </a>
          </div>
        </div>
        <div :class="['info-app-box-alert',
        isEnabled ? 'info-app-box-alert-success' : 'info-app-box-alert-danger', 
        isEnabled ? 'alert-adjustment-system-success':'alert-adjustment-system-danger'
      ]"
    >
    <div>
      <i
        :class="[
          'fa-solid',
          isEnabled ? 'fa-circle-check' : 'fa-circle-exclamation'
        ]"
      ></i>
    </div>
    <div>
      <span v-html="isEnabled ? $t('OkCompany') : $t('NotOkCompany')"></span>
    </div>
    </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import sourcingLogo from "../assets/images/wherex-logo.svg";
  export default {
    components: {},
    props: {
    isEnabled: Boolean,
    },
    data: function () {
      return {
        logo: sourcingLogo
     };
    },
    mounted: function () {
    },
    methods:{
      goSourcing: function(){
        const redirectUrl = this.$utils.getRedirectUrl(
          this.$sourcingLogin,
          this.$local,
          this.$whiteList
        );
  
        const tokenForm = this.$utils.getTokenForm(redirectUrl, this.$clientId, this.$userInfo.getEmail(this.$clientId))
        document.getElementById("tokenForm").appendChild(tokenForm);
        tokenForm.submit();
      }
    
    },
  
  };
  </script>
  
  <style>
  </style>