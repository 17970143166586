<template>
  <!-- End Top Bar -->
  <div class="container g-0 mt-0" style="padding-top: 29px;">     
      <div class="row">
          <div class="col">
          <div class="d-flex justify-content-center flex-column flex-md-row">
            <SystemCard  v-bind:isEnabled="this.sourcing" />
            <Fintechcard v-bind:isEnabled="this.pay" v-bind:isUserSystem="this.sourcing"/>
            <RiskCard v-bind:isEnabled="this.risk" v-bind:isUserSystem="this.risk"/>
          </div>
          <div id="tokenForm" style="display: none;"></div> 
        </div>
      </div>
  </div>
  </template>
  
  <script>
  import SystemCard from "@/components/SystemCard.vue";
  import Fintechcard from "@/components/FintechCard.vue";
  import RiskCard from "@/components/RiskCard.vue";
  export default {
    components:{SystemCard,Fintechcard,RiskCard},
    props:{
      sourcing:Boolean,
      pay:Boolean,
      risk:Boolean,
    },
    mounted: async function(){}
  }
  </script>
  
  <style>
  @import "../assets/css/table.css";
  </style>