import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { CognitoService } from './plugins/CognitoServices'
import { UserInfo } from './plugins/UserInfo'
import { Utils } from './plugins/Utils'
import { Log } from './plugins/log'
import i18n from './plugins/i18n'
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Userpilot } from 'userpilot';

//Initialize Userpilot
Userpilot.initialize('NX-d7faabf1');

//identify guest user
Userpilot.identify(
    'guestUser',
    {
        name: 'guestUser',
        email: 'noreply@wherex.com',
        created_at: new Date().toISOString(),
    }
)

const app = createApp(App, { i18n, router });

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};

const http = axios.create();
http.get('/config/env.json').then(async response => {
    app.config.globalProperties.$userPoolId = response.data.userPool
    app.config.globalProperties.$clientId = response.data.client
    app.config.globalProperties.$local = response.data.local
    app.config.globalProperties.$sourcingLogin = response.data.sourcingLogin
    app.config.globalProperties.$payLogin = response.data.payLogin
    app.config.globalProperties.$sourcingInfo1 = response.data.sourcingInfo1
    app.config.globalProperties.$sourcingInfo2 = response.data.sourcingInfo2
    app.config.globalProperties.$payInfo1 = response.data.payInfo1
    app.config.globalProperties.$payInfo2 = response.data.payInfo2
    app.config.globalProperties.$logUrl = response.data.logUrl
    app.config.globalProperties.$payTerms = response.data.payTerms
    app.config.globalProperties.$payOneClick = response.data.payOneClick
    app.config.globalProperties.$cog = new CognitoService()
    app.config.globalProperties.$userInfo = new UserInfo()
    app.config.globalProperties.$utils = new Utils()
    app.config.globalProperties.$log = new Log()
    app.config.globalProperties.$env = response.data.env
    app.config.globalProperties.$whiteList = response.data.whiteList
    app.config.globalProperties.$tokenEndpoint = response.data.tokenEndpoint
    app.config.globalProperties.$redirectUri = response.data.redirectUri
    app.config.globalProperties.$riskLogin = response.data.riskLogin
    app.config.globalProperties.$riskUrlInfo = response.data.riskUrlInfo
    app.config.globalProperties.$riskTerms = response.data.riskTerms
    app.use(router).use(i18n).use(VueSweetalert2, options).mount('#app')
});




